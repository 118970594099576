import React, { useEffect, useRef, lazy, Suspense, useState } from "react";
import "./video-player.scss";
import "plyr/dist/plyr.css";

// Dynamically import Plyr to avoid SSR issues
const Plyr = lazy(() => import("react-plyr"));

export const VideoPlayer = ({ videoUrl, poster }) => {
  const videoRef = useRef(null);
  const [player, setPlayer] = useState(null); 

  useEffect(() => {
    if (player) {
      const plyrInstance = player;


      plyrInstance.muted = true;
      plyrInstance.volume = 0;

      // Access the media element (native video) through Plyr's API
      const videoElement = plyrInstance.media;

      console.log('Video element:', videoElement);

      if (videoElement) {
        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                console.log('Video in view, attempting to play');
                plyrInstance.muted = true; // Ensure it remains muted
                plyrInstance.play().catch((error) => {
                  console.error('Plyr autoplay error: ', error);
                });
              } else {
                console.log('Video out of view, pausing');
                plyrInstance.pause();
              }
            });
          },
          {
            threshold: 0.25, // Video plays when 25% visible
          }
        );

        observer.observe(videoElement); // Observe the actual video DOM element

        return () => {
          observer.unobserve(videoElement); // Cleanup observer on component unmount
        };
      }
    }
  }, [player]); // Re-run effect when player is set

  return (
    <div className="video-player">
      <Suspense fallback={<div>Loading video player...</div>}>
        {typeof window !== "undefined" && (
          <Plyr
            ref={videoRef}
            type="video"
            url={videoUrl}
            poster={poster}
            options={{
              autoplay: false, // We control autoplay manually with IntersectionObserver
              loop: { active: true },
              controls: ["play", "progress", "fullscreen"],
              muted: true, 
              playsinline: true, 
            }}
            onReady={(plyrInstance) => {
              setPlayer(plyrInstance); // Set the Plyr instance
              plyrInstance.muted = true; // Mute right after initialization
              plyrInstance.volume = 0; // Set volume to 0
              console.log('Plyr instance ready:', plyrInstance);
            }}
          />
        )}
      </Suspense>
    </div>
  );
};
