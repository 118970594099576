import React from "react";
import Layout from "../../components/layout/layout";
import CtaSection from "../../components/CtaSection/CtaSection";
import { Container } from "../../components/Container/Container";
import { Column } from "../../components/Column/Column";
import { graphql, useStaticQuery } from "gatsby";
import { PostFold } from "../../components/PostFold/PostFold";
import { CardQuote } from "../../components/CardQuote/CardQuote";
import { TechItem } from "../../components/TechItem/TechItem";
import { VideoPlayer } from "../../components/VideoPlayer/VideoPlayer";

const IMAGE_QUERY = graphql`
  {
    projectImage: file(relativePath: { eq: "our-work/dutydingo-project.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 952
          placeholder: BLURRED
          formats: [AUTO, JPG, WEBP, AVIF]
        )
      }
      publicURL  
    }
    imagePascal: file(relativePath: { eq: "about-us/pascal-ehlert.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 500
          placeholder: BLURRED
          formats: [AUTO, JPG, WEBP, AVIF]
        )
      }
    }
    iconTypescript: file(relativePath: { eq: "technologies/typescript.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 60
          placeholder: BLURRED
          formats: [AUTO, JPG, WEBP, AVIF]
        )
      }
    }
    iconNodeJs: file(relativePath: { eq: "technologies/nodejs.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 60
          placeholder: BLURRED
          formats: [AUTO, JPG, WEBP, AVIF]
        )
      }
    }
    iconPostgreSql: file(relativePath: { eq: "technologies/postgreql.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 60
          placeholder: BLURRED
          formats: [AUTO, JPG, WEBP, AVIF]
        )
      }
    }
    iconOpenAi: file(relativePath: { eq: "technologies/open-ai.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 60
          placeholder: BLURRED
          formats: [AUTO, JPG, WEBP, AVIF]
        )
      }
    }
    iconDsPy: file(relativePath: { eq: "technologies/dspy.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 60
          placeholder: BLURRED
          formats: [AUTO, JPG, WEBP, AVIF]
        )
      }
    }
    iconLlama: file(relativePath: { eq: "technologies/llama.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 60
          placeholder: BLURRED
          formats: [AUTO, JPG, WEBP, AVIF]
        )
      }
    }
    iconQdrant: file(relativePath: { eq: "technologies/qdrant.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 60
          placeholder: BLURRED
          formats: [AUTO, JPG, WEBP, AVIF]
        )
      }
    }
    iconSupabase: file(relativePath: { eq: "technologies/supabase.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 60
          placeholder: BLURRED
          formats: [AUTO, JPG, WEBP, AVIF]
        )
      }
    }
  }
`;

const OurWorkDutyDingo = () => {
  const tempImage = useStaticQuery(IMAGE_QUERY);
  return (
    <Layout
      pageTitle="Kickstage Develops AI-Powered Chatbot for DutyDingo"
      description="DutyDingo, a startup founded by two seasoned software development agency owners, approached us with a vision to revolutionize project management in the tech industry. Their goal was to streamline the often cumbersome process of creating tickets from chat conversations and managing project information more efficiently."
      image={tempImage.projectImage.publicURL} 
    >
      <PostFold
        title="<strong>Kickstage Develops</strong> </br> AI-Powered Chatbot for DutyDingo"
        image={tempImage.projectImage}
        backLinkUrl="/our-work"
        backLinkTitle="Our Work"
        wrapHeading
      />

      <Container>
        <Column columns_mobile="12" columns_tablet="12" columns_desktop="12">
          <div className="blog-post">
            <div className="blog-post__description">
              <p>
                <a
                  href="https://www.dutydingo.ai/"
                  target="_blank"
                  rel="noreferrer"
                >
                  DutyDingo
                </a>
                , a startup founded by two seasoned software development agency
                owners, approached us with a vision torevolutionize project
                management in the tech industry. Their goal was to streamline
                the often cumbersome process ofcreating tickets from chat
                conversations and managing project information more efficiently.
              </p>
            </div>
            <div className="blog-post__main-holder">
              <h2>The Challenge</h2>
              <p>
                The founders of{" "}
                <a
                  href="https://www.dutydingo.ai/"
                  target="_blank"
                  rel="noreferrer"
                >
                  DutyDingo
                </a>{" "}
                had identified a persistent pain point in software development
                agencies. Creating tickets from Slack discussions was a
                time-consuming and repetitive task, often leading to information
                loss and context fragmentation. Project managers struggled to
                capture all relevant information from chat conversations, and
                teams frequently lost track of previous discussions, resulting
                in redundant conversations and reduced productivity.
              </p>
              <h2>Kickstage's Solution</h2>
              <p>
                To address these challenges, we developed a sophisticated
                AI-powered Slack chatbot. This innovative tool automatically
                creates tasks from Slack conversations, seamlessly integrates
                with popular project management platforms like Trello, Jira,
                Asana, and ClickUp, and maintains context from past
                conversations. It also efficiently handles multimedia content
                attachments, ensuring no crucial information is lost in the
                process. The technology stack behind this solution is as
                impressive as its functionality. The development team leveraged
                DSPy for prompt optimization, LLAMA 3 for local model execution
                to ensure data privacy, and Kubernetes for scalable multi-tenant
                deployments. The team also experimented with pgVector and Qdrant
                for efficient vector database solutions. The backend, written in
                TypeScript and running on Node.js, uses Supabase as a reliable,
                vendor-agnostic database system, future-proofing the
                architecture for potential scaling and evolution.
              </p>
              <VideoPlayer
                videoUrl="/videos/duty-dingo.mp4"
                poster="/videos/chatbot-cover.jpg"
              />

              <h2>Technical Challenges and Solutions</h2>
              <p>
                One of the primary hurdles we faced was optimizing prompts to
                generate consistent results in the format customersexpected. The
                initial trial-and-error approach had its limitations, which led
                to the adoption of DSPy, a framework foralgorithmically
                optimizing language model prompts and weights. This tool allowed
                us to break down complex problems, fine-tune prompts for optimal
                performance, and adapt quickly to changes in the pipeline,
                language model, or data without extensive manual adjustments.{" "}
              </p>
              <p>
                Data privacy emerged as another critical concern during
                development. Initially using public models like OpenAI's GPT-4
                and Anthropic's Claude, we quickly recognized the need for a
                more secure solution. In partnership with Hostzero, we
                implemented a scalable, self-hosted approach using the
                open-source LLAMA 3 model on Kubernetes. This pivot ensured that
                customer data never leaves their servers while maintaining the
                high-quality results expected from public models.
              </p>

              <div className="grid-holder">
                <TechItem title="Open AI" image={tempImage.iconOpenAi} />
                <TechItem title="DSPy" image={tempImage.iconDsPy} />
                <TechItem title="Llama" image={tempImage.iconLlama} />
                <TechItem title="Postgres" image={tempImage.iconPostgreSql} />
                <TechItem title="Qdrant" image={tempImage.iconQdrant} />
                <TechItem title="Supabase" image={tempImage.iconSupabase} />
                <TechItem title="Node.JS" image={tempImage.iconNodeJs} />
                <TechItem title="TypeScript" image={tempImage.iconTypescript} />
              </div>

              <h2>Results and Impact</h2>
              <p>
                The impact of our solution for DutyDingo has been substantial.
                After a year of operation, clients reported
                significantimprovements in their software development process.
                Development teams experienced reduced context switching and
                improved accuracy in ticket creation, leading to up to{" "}
                <strong>5% faster product shipping</strong> times. On average,
                clients saved<strong> 8 man-hours per project monthly</strong>.
                Perhaps most importantly,{" "}
                <strong>developer satisfaction</strong> noticeably increased due
                to theimproved workflow and reduced administrative overhead.
              </p>
              <CardQuote
                fullName="Pascal Ehlert"
                image={tempImage.imagePascal}
                position="Co-Founder of Kickstage"
                citation="Working with the DutyDingo team on this cutting-edge technology was an incredible opportunity to deliver real value to clients. We were so impressed with the product that we immediately integrated it into our own internal technology stack."
              />
              <h2>Conclusion</h2>
              <p>
                Through this project, our teams demonstrated their expertise in
                AI, cloud technologies, and software integration,transforming a
                common industry pain point into an innovative, time-saving
                solution. By overcoming significant technical challenges in
                prompt optimization and data privacy, we delivered a robust,
                scalable product that not only met but exceeded client
                expectations. This case study showcases our ability to tackle
                complex problems and deliver solutions that provide tangible,
                measurable benefits to clients in the software development
                industry.
              </p>
            </div>
          </div>
        </Column>
      </Container>

      <CtaSection
        title="Let's Chat"
        label="What Can We Do For You?"
        link="/contact/"
      />
    </Layout>
  );
};

export default OurWorkDutyDingo;
